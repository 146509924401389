import { ShopeePayIcon } from "@sipp/tangerine";
import React from "react";
import QR from "src/Assets/Icons/ShopeeAppQR/QR.png";

import {
  COPYRIGHT_CONTENT,
  DOWNLOAD_CONTENT,
  EXPLORE_CONTENT,
  SERVICE_CONTENT,
  SOCIAL_MEDIA_CONTENT,
} from "../AppFooter/Constants";

import "./index.less";

const FollowUs = () => {
  const { label, content } = SOCIAL_MEDIA_CONTENT;

  return (
    <div className="footer-content__follow-us">
      <h1>{label}</h1>
      <div className="footer-content__follow-us-content">
        {content.map(({ icon, url }) => (
          <a
            key={url}
            href={url}
            className="qr-code"
            target="_blank"
            rel="noreferrer"
          >
            {<img src={icon} alt="Download Icon" width={40} />}
          </a>
        ))}
      </div>
    </div>
  );
};
const DownloadOnSosmed = () => {
  const { label, content } = DOWNLOAD_CONTENT;

  return (
    <div className="footer-content__download">
      <h1>{label}</h1>
      <div className="footer-content__download-content">
        {content.map(({ icon, url }) => (
          <a
            key={url}
            href={url}
            className="qr-code"
            target="_blank"
            rel="noreferrer"
          >
            {<img src={icon} alt="Download Icon" width={115} />}
          </a>
        ))}
      </div>

      <a href="https://shopee.ph/web" className="qr-code">
        <img src={QR} alt="PH QR code" width={65} />
      </a>
    </div>
  );
};

const OtherLinks = () => {
  return (
    <div className="footer-content__other-links">
      <div className="footer-content__other-links-content">
        <div className="footer-content__other-links-content-item">
          <p>{SERVICE_CONTENT.label}</p>
          {SERVICE_CONTENT.content.map((link) => {
            const openNewTab = link.url.includes("https:/");

            return (
              <li>
                <a
                  {...link?.props}
                  key={link.url}
                  href={link.url}
                  rel="noreferrer"
                  target={openNewTab ? "_blank" : "_self"}
                >
                  {link.label}
                </a>
              </li>
            );
          })}
        </div>

        <div className="footer-content__other-links-content-item">
          <p>{EXPLORE_CONTENT.label}</p>
          {EXPLORE_CONTENT.content.map((link) => {
            const openNewTab = link.url.includes("https:/");

            return (
              <li>
                <a
                  {...link?.props}
                  key={link.url}
                  href={link.url}
                  rel="noreferrer"
                  target={openNewTab ? "_blank" : "_self"}
                >
                  {link.label}
                </a>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Copyright = () => {
  const [TermOfService, PrivacyPolicy] = COPYRIGHT_CONTENT.content;

  return (
    <div className="footer-copyright">
      <p>
        {COPYRIGHT_CONTENT.label}
        <a {...TermOfService.props}>{TermOfService.label}</a> |{" "}
        <a {...PrivacyPolicy.props}>{PrivacyPolicy.label}</a>
      </p>
    </div>
  );
};
function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <section className="footer-content__left">
            <ShopeePayIcon />
            <FollowUs />
          </section>
          <section className="footer-content__right">
            <OtherLinks />
            <DownloadOnSosmed />
          </section>
        </div>
        <Copyright />
      </footer>
    </>
  );
}

export default Footer;
